<template>
    <div>
        <a-modal :title="getTitle" :width="480" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div>
                    <div>
                        <div class="flex center">
                            <upload-img v-model="form.icon"></upload-img>
                        </div>
                        <div class="flex center mt8">
                            <div class="ft12 ftw400 cl-notice">分类图标：建议上传200x200px</div>
                        </div>
                    </div>

                    <div class="mt24">
                        <a-tree-select v-model="form.pid" style="width: 100%"
                                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="category"
                                       placeholder="Please select" >
                        </a-tree-select>
                    </div>
                    <div class="flex  mt24">
                        <a-input class="" style="width: 230px;" placeholder="请输入分类名称" v-model="form.name"
                                 :maxLength="20">
                        </a-input>
                        <a-input-number class=" ml20" style="width: 230px;" placeholder="请输入排序号(越大越靠前)"
                                        v-model="form.sort" :precision="0" :min="0"/>
                    </div>
                    <div  class="flex  mt24">
                        <a-form-model-item  label="是否首页推荐">
                            <a-radio-group v-model="form.recommend_status">
                                <a-radio :value="0">否</a-radio>
                                <a-radio :value="1">是</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item required label="是否启用">
                            <a-radio-group v-model="form.status">
                                <a-radio :value="0">否</a-radio>
                                <a-radio :value="1">是</a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
    import uploadImg from '../../../../../components/upload/upload.vue';
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            article_category_id: {
                type: Number,
                default: 0,
            }
        },
        components: {
            uploadImg,
        },
        data() {
            return {
                loading: false,
                confirmLoading: false,
                form: {
                    icon: '',
                    name: '',
                    sort: '',
                    pid:0,
                    status:1,
                    recommend_status:1,

                },
                category:[]
            }
        },
        computed: {
            getTitle() {
                if (this.article_category_id == 0) {
                    return "添加文章分类";
                } else {
                    return "编辑文章分类"
                }
            }
        },
        created() {
            this.loaddata();
        },
        methods: {

            loaddata(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('platform/articlecategory/show',{
                    article_category_id:this.article_category_id,
                }).then(res=>{
                    if(res.detail){
                        this.form=res.detail;
                    }
                    if(res.category){
                        this.category=res.category;
                    }
                    this.loading=false;
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                })
            },

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },

            /**
             * 提交数据
             */
            onSubmit() {
                if(this.confirmLoading==true) return;
                this.confirmLoading=true;
                this.$http.api('platform/articlecategory/save',{
                    article_category_id:this.article_category_id,
                    name:this.form.name,
                    icon:this.form.icon,
                    sort:this.form.sort,
                    pid:this.form.pid,
                    status:this.form.status,
                    recommend_status:this.form.recommend_status,
                }).then(res=>{
                    this.$message.success('保存成功',1,()=>{
                        this.confirmLoading=false;
                        this.$emit("ok");
                    })

                }).catch(res=>{
                    console.log(res);
                    this.confirmLoading=false;
                })

            },

        }
    }
</script>

<style>

</style>
